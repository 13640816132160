export const SOLUTIONS: {
  [key: string]: string
} = {
  // April
  '10.4.2022': 'pocket', // Surprise! (Pocket of Coat) √
  '11.4.2022': 'love',
  '12.4.2022': 'beautiful',
  '13.4.2022': 'call me', // Online message
  '14.4.2022': 'privacy', // Online Message
  '15.4.2022': 'eyes', // Online Message
  '16.4.2022': 'candy', // Online Message
  '17.4.2022': 'wedding', // Quote 2 Points -Pillow √
  '18.4.2022': 'happy', // Quote Point + Bonus Point - Calendar Living Room √
  '19.4.2022': 'sorry',
  '20.4.2022': 'energy',
  '21.4.2022': 'caring', // Bacanal Tickets √
  '22.4.2022': 'chaiwala',
  '23.4.2022': 'light', // Quote Point - Heart Lamp (TV Spot) √
  '24.4.2022': 'sexy',
  '25.4.2022': 'babu',
  '26.4.2022': 'smile',
  '27.4.2022': 'hug',
  '28.4.2022': 'snuggle', // Quote Point - Teddy Bear √
  '29.4.2022': 'infinite',
  '30.4.2022': 'outlet',

  // May
  '1.5.2022': 'leprechaun', // Quote Clue - TV √
  '2.5.2022': 'beer',
  '3.5.2022': 'guinness',
  '4.5.2022': 'cider',
  '5.5.2022': 'clover', // Quote Point - Wedding Notebook √
  '6.5.2022': 'gold',
  '7.5.2022': 'irish',
  '8.5.2022': 'pie',
  '9.5.2022': 'plane',
  '10.5.2022': 'notre dame', // 20 Quote Point Part 1 - Notredame √
  '11.5.2022': 'coffee',
  '12.5.2022': 'chart',
  '13.5.2022': 'box',
  '14.5.2022': 'fire',
  '15.5.2022': 'keys', // 20 Quote Point Part 2 - Keys Holder √
  '16.5.2022': 'book',
  '17.5.2022': 'galician',
  '18.5.2022': 'wee birthday',
  '19.5.2022': 'thanks',
  '20.5.2022': 'farewell', // 10 Quote Points - Popsy Coaster √
  '21.5.2022': 'cheesy',
  '22.5.2022': 'love you',
  '23.5.2022': 'trip',
  '24.5.2022': 'printer',
  '25.5.2022': 'wine',
  '26.5.2022': 'late',
  '27.5.2022': 'peachy',
  '28.5.2022': 'banana',
  '29.5.2022': 'tv',
  '30.5.2022': 'siri', // 1 Quote Point - Homepod √
  '31.5.2022': 'alarm',

  // June
  '1.6.2022': 'microsoft',
  '2.6.2022': 'house',
  '3.6.2022': 'dublin',
  '4.6.2022': 'countdown',
  '5.6.2022': 'michelin',
  '6.6.2022': 'aniversary', // Big Gift! - Pendant √
  '7.6.2022': 'enjoy',
  '8.6.2022': 'missing',
  '9.6.2022': 'thinking',
  '10.6.2022': 'candle', // Quote Point (Laura's Candle) √
  '11.6.2022': 'match',
  '12.6.2022': 'remote',
  '13.6.2022': 'ring',
  '14.6.2022': 'game of thrones',
  '15.6.2022': 'cookies', // Quote Point (Cookies Drawer Lab or Roti Maker - Laura) √
  '16.6.2022': 'glass',
  '17.6.2022': 'tiffin',
  '18.6.2022': 'clock',
  '19.6.2022': 'lovers',
  '20.6.2022': 'clinic', // Big Gift!! - Trip (Clinic Game - Laura's House) √
  '21.6.2022': 'monkeys',
  '22.6.2022': 'car',
  '23.6.2022': 'carrom',
  '24.6.2022': 'travel',
  '25.6.2022': 'taj mahal', // Quote Point (The Taj Mahal) √
  '26.6.2022': 'london',
  '27.6.2022': 'tea',
  '28.6.2022': 'stones',
  '29.6.2022': 'door',
  '30.6.2022': 'collage', // Quote Point (Living Room Frame with many pictures) √

  // July
  '1.7.2022': 'luck', // 1 Quote Point - Underwear √
  '2.7.2022': 'makeup',
  '3.7.2022': 'calendar',
  '4.7.2022': 'puzzle',
  '5.7.2022': 'work',
  '6.7.2022': 'tower', // Quote Point (Tower of London) √
  '7.7.2022': 'elefant',
  '8.7.2022': 'shoes',
  '9.7.2022': 'storage',
  '10.7.2022': 'table',
  '11.7.2022': 'eliptical', // -1 Point - Dildo √
  '12.7.2022': 'fan',
  '13.7.2022': 'spices',
  '14.7.2022': 'panties',
  '15.7.2022': 'meds',
  '16.7.2022': 'horny', // 1 Quote Point - Matric Number Door √
  '17.7.2022': 'curtain',
  '18.7.2022': 'far',
  '19.7.2022': 'plants',
  '20.7.2022': 'camping',
  '21.7.2022': 'family', // 1 Quote Point - Family Frame √
  '22.7.2022': 'park',
  '23.7.2022': 'paper',
  '24.7.2022': 'rock',
  '25.7.2022': 'scissors',
  '26.7.2022': 'monica', // 2 Quote Points + 2 Love Points + Full Body Masage -Monica Door Picture √
  '27.7.2022': 'more',
  '28.7.2022': 'time',
  '29.7.2022': 'sky',
  '30.7.2022': 'clouds',
  '31.7.2022': 'castle', // 1 Quote Point - Game of Thrones Castle √

  // August
  '1.8.2022': 'month',
  '2.8.2022': 'gifts',
  '3.8.2022': 'want you',
  '4.8.2022': 'nice',
  '5.8.2022': 'love it', // Quote Point - Jewlery Box √
  '6.8.2022': 'yago',
  '7.8.2022': 'when',
  '8.8.2022': 'mine',
  '9.8.2022': 'food',
  '10.8.2022': 'experience', // Quote Point - White Dress with transparent arms √
  '11.8.2022': 'ready',
  '12.8.2022': 'brag',
  '13.8.2022': 'celebrate',
  '14.8.2022': 'dance',
  '15.8.2022': 'sing', // Quote Point -  Shoe Rack √
  '16.8.2022': 'flying',
  '17.8.2022': 'canoe',
  '18.8.2022': 'join me',
  '19.8.2022': 'awesome',
  '20.8.2022': 'soon enough', // Quote Point - Socks drawer (inside white pants) √
  '21.8.2022': 'patience',
  '22.8.2022': 'running', // Nice Gift!! - Michelin √
  '23.8.2022': 'year',
  '24.8.2022': 'rabbit',
  '25.8.2022': 'beach',
  '26.8.2022': 'ending',
  '27.8.2022': 'game',
  '28.8.2022': 'visa',
  '29.8.2022': 'documents',
  '30.8.2022': 'nationality',
  '31.8.2022': 'i love you', // Quote Point - Kitchen Drawer (under fruits) - under the fork stand √
}

export const HAS_GIFT: Array<string> = [
  'pocket', // 10.4.2022
  'call me', // 13.4.2022
  'privacy', // 14.4.2022
  'eyes', // 15.4.2022
  'candy', // 16.4.2022 - Old: Home, Packing
  'special', // 17.4.2022 - Old: Return
  'happy', // 18.4.2022
  'energy', // 20.4.2022 - Old: Baby
  'caring', // 21.4.2022 - Old: Horror
  'light', // 23.4.2022
  'snuggle', // 28.4.2022

  'leprechaun', // 01.5.2022
  'clover', // 05.5.2022
  'notre dame', // 10.5.2022
  'keys', // 15.5.2022
  'farewell', // 20.5.2022
  'siri', // 30.5.2022

  'wedding', // 06.06.2022
  'clinic', // 20.6.2022
  'candle', // 10.6.2022
  'cookies', // 15.6.2022
  'taj mahal', // 25.6.2022
  'collage', // 30.6.2022

  'luck', // 01.7.2022
  'tower', // 06.07.2022
  'eliptical', // 11.07.2022
  'horny', // 16.07.2022
  'family', // 21.07.2022
  'monica', // 26.07.2022
  'castle', // 31.07.2022

  'love it', // 5.8.2022
  'experience', // 10.8.2022
  'sing', // 15.8.2022
  'soon enough', // 20.8.2022
  'running', // 22.8.2022
  'i love you', // 31.8.2022
]

export const decodeSolutionMessage = (solution: string): string => {
  const solutionLowerCase = `${solution || ''}`.toLowerCase()
  let winMessage = `Go Find the gift in the ${solution}`
  debugger

  if (solutionLowerCase === 'candle') {
    winMessage = `Go Find the gift in the borrowed ${solution}`
  } else if (solutionLowerCase === 'return') {
    winMessage = `${solution} to your HAPPY PLACE and REST your HEAD for your gift LIES there!`
  } else if (solutionLowerCase === 'cookies') {
    winMessage = `Go Find the gift in the lab's ${solution} DRAWER!`
  } else if (solutionLowerCase === 'running') {
    winMessage = `Go Find the gift in the ${solution} MACHINE!`
  } else if (solutionLowerCase === 'wedding') {
    winMessage = `Happy ${solution} Aniversary! Go ask your hubby for your gift!`
  } else if (solutionLowerCase === 'light') {
    winMessage = `SPOT ${solution} is the Key!`
  } else if (solutionLowerCase === 'happy') {
    winMessage = `Your gift lies in TWO ${solution} DATES!`
  } else if (solutionLowerCase === 'snuggle') {
    winMessage = `Your gift ${solution}S very well!`
  } else if (solutionLowerCase === 'leprechaun') {
    winMessage = `A ${solution} you'll find, In the place where all movies become true.`
  } else if (solutionLowerCase === 'clover') {
    winMessage = `A lucky ${solution} you'll find, in the happy day diary you have!`
  } else if (solutionLowerCase === 'notre dame') {
    winMessage = `${solution} holds half the clue!`
  } else if (solutionLowerCase === 'keys') {
    winMessage = `${solution} behind the door for the second part of the clue!`
  } else if (solutionLowerCase === 'farewell') {
    winMessage = `${solution} to your hubby's job, but your clue is in the Logo!`
  } else if (solutionLowerCase === 'luck') {
    winMessage = `${solution} or no ${solution}, the gift is on your SEXY drawer.`
  } else if (solutionLowerCase === 'eliptical') {
    winMessage = `You do ${solution} movements with me, and pleasure will come.`
  } else if (solutionLowerCase === 'horny') {
    winMessage = `You drive me ${solution}! Your gift is in the Sign!`
  } else if (solutionLowerCase === 'love it') {
    winMessage = `${solution}! You say when you see them... your gift is where you store them!`
  } else if (solutionLowerCase === 'experience') {
    winMessage = `Is White, Is Transparent and Is Hanging... (${solution} has nothing to do with this hideout)`
  } else if (solutionLowerCase === 'sing') {
    winMessage = `I am ${solution}ING in the rain!! with my very worm shoes ;)`
  } else if (solutionLowerCase === 'soon enough') {
    winMessage = `${solution} I'll see you without these my love!`
  } else if (solutionLowerCase === 'i love you') {
    winMessage = `${solution}! btw, your gift is somewhere in the kitchen ;)`
  } else if (solutionLowerCase === 'call me') {
    winMessage = `${solution}! for real, your hubby misses you!! 😘 😘 😘`
  } else if (solutionLowerCase === 'privacy') {
    winMessage = `Your ${solution} is Gone! but you enjoy the trip, you deserve it!! 😘 😘`
  } else if (solutionLowerCase === 'home') {
    winMessage = `${solution} is waiting for you! and your hubby tooo!!`
  } else if (solutionLowerCase === 'siri') {
    winMessage = `Tell ${solution} I love you, and maybe it also has something hidden for you.`
  } else if (solutionLowerCase === 'eyes') {
    winMessage = `Your ${solution} are beautiful!`
  } else if (solutionLowerCase === 'packing') {
    winMessage = `I finished ${solution}!! 💃 🕺`
  } else if (solutionLowerCase === 'horror') {
    winMessage = `${solution} tickets you'll find, under the printer!!`
  } else if (solutionLowerCase === 'candy') {
    winMessage = `You are sweet like ${solution}!! 😘 😘`
  } else if (solutionLowerCase === 'special') {
    winMessage = `You are awesome, your are the best, you are ${solution}!! 😘 😘 (I love you sooo much!! 🥰 🥰)`
  } else if (solutionLowerCase === 'energy') {
    winMessage = `You need to rest and gather some ${solution} 😯 (I love you! 😘 😘)`
  } else if (solutionLowerCase === 'caring') {
    winMessage = `Thanks for ${solution} about hour HUBBY!! 😘 😘`
  }

  return winMessage
}
