import { solution } from '../lib/words'

let chances = 6
if (solution.length <= 2) {
  chances = 6
} else if (solution.length <= 4) {
  chances = 6
} else if (solution.length >= 7) {
  chances = 8
} else if (solution.length >= 9) {
  chances = 10
} else if (solution.length >= 11) {
  chances = 13
} else if (solution.length >= 13) {
  chances = 15
}

if (solution.includes(' ')) {
  chances = chances + 2
}

export const MAX_WORD_LENGTH = solution.length
export const MAX_CHALLENGES = chances
export const ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const GAME_LOST_INFO_DELAY = (MAX_WORD_LENGTH + 1) * REVEAL_TIME_MS
export const WELCOME_INFO_MODAL_MS = 350
